/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/@xiee/utils@1.13.70/js/fix-toc.min.js
 * - /npm/@xiee/utils@1.13.70/js/center-img.min.js
 * - /npm/@xiee/utils@1.13.70/js/right-quote.min.js
 * - /npm/@xiee/utils@1.13.70/js/no-highlight.min.js
 * - /npm/@xiee/utils@1.13.70/js/fix-footnote.min.js
 * - /npm/@xiee/utils@1.13.70/js/math-code.min.js
 * - /npm/@xiee/utils@1.13.70/js/external-link.min.js
 * - /npm/@xiee/utils@1.13.70/js/alt-title.min.js
 * - /npm/@xiee/utils@1.2.12/js/header-link.min.js
 * - /npm/@xiee/utils@1.13.70/js/hash-notes.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
